$(document).ready(function(){
    dienstenSlider();
    klantenSlider();


    //TRIGGER RESPONSIVE MENU
    $('#trigger').click(function(){
        if($('body').hasClass('open')){
            $('body').removeClass('open');
            $('header nav .menuwrapper').slideUp();
        }else{
            $('body').addClass('open');
            $('header nav .menuwrapper').slideDown();
        }
    });


    $('li.menu-item-has-children > a').click(function(e){
        e.preventDefault();
        if($(this).parent().hasClass('open')){
            $(this).parent().find('ul.sub-menu').slideUp();
            $(this).parent().removeClass('open');
        }else{
            $(this).parent().find('ul.sub-menu').slideDown();
            $(this).parent().addClass('open');
        }
    });


    //ISOTOPE
    $('ul.referencesgrid').isotope({
        itemSelector: '.referencesgrid-item',
        percentPosition: true,
    });

    var jobcounter = $('header nav').attr('data-counter');
    if(jobcounter > 0){
        $('li.counter').append('<div class="bol">'+jobcounter+'</div>');
    }


    //OTHER SITES SELECTOR
    $('select#othersiteslist').on('change', function() {
        var url = $(this).val();
        if(url != 'none') {
            window.open(url);
        }
        $("#othersiteslist").val($("#othersiteslist option:first").val());
    });
});



function dienstenSlider(){
    if($(window).width() > 991){
        var slides = 3;
    }else if($(window).width() > 768){
        var slides = 2;
    }else{
        var slides = 1;
    }
    $('#diensten > ul').bxSlider({
        pager: true,
        controls: false,
        minSlides: slides,
        maxSlides: slides,
        shrinkItems: true,
        slideWidth: 1000,
        slideMargin: 30,
    });
}

function klantenSlider(){
    if($(window).width() > 1300){
        var slides = 6;
    }else if($(window).width() > 991){
        var slides = 5;
    }else if($(window).width() > 765){
        var slides = 4;
    }else if($(window).width() > 600){
        var slides = 3;
    }else if($(window).width() > 400){
        var slides = 2;
    }else{
        var slides = 1;
    }
    $('#klanten .bxslider').bxSlider({
        pager: true,
        controls: false,
        minSlides: slides,
        maxSlides: slides,
        shrinkItems: true,
        slideWidth: 1000,
        slideMargin: 30,
    });
}


equalheight = function(container){
var currentTallest = 0,
     currentRowStart = 0,
     rowDivs = new Array(),
     $el,
     topPosition = 0;
 $(container).each(function() {
   $el = $(this);
   $($el).height('auto')
   topPostion = $el.position().top;
   if (currentRowStart != topPostion) {
     for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
       rowDivs[currentDiv].height(currentTallest);
     }
     rowDivs.length = 0; // empty the array
     currentRowStart = topPostion;
     currentTallest = $el.height();
     rowDivs.push($el);
   } else {
     rowDivs.push($el);
     currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
  }
   for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
     rowDivs[currentDiv].height(currentTallest);
   }
 });
}
$(window).load(function() {
    equalheight('#diensten .bx-wrapper .bx-viewport > ul > li');
    equalheight('#klanten .bx-wrapper .bx-viewport > ul > li');
    equalheight('#cat-news ul.newsgrid li');
});
$(window).resize(function(){
    equalheight('#diensten .bx-wrapper .bx-viewport > ul > li');
    equalheight('#klanten .bx-wrapper .bx-viewport > ul > li');
    equalheight('#cat-news ul.newsgrid li');

    dienstenSlider();
    klantenSlider();
});
